import network from "./instance"
import authModule from "./auth"
import cookieModule from "./cookie"
import htmlModule from "./html"
import filesModule from './files'
import wellsModule from './wells'

import { EventBus } from "./eventBus"

network.setEventBus(EventBus)

export default {
    auth : authModule(network),
    files : filesModule(network),
    wells : wellsModule(network),

    html : htmlModule(),
    cookie: cookieModule(),
    eventBus : EventBus,
}