<template>
  <div>
    <page-title
    :title="username"
    />
    <div class="card-wrap-full">
      <div class="card">
        <p class="account-green" v-if="isExtended">У Вас доступ ко всем функциям Magicore</p>
        <p class="account-red" v-if="!isExtended">Вы имеете ограниченный доступ</p>
        <p v-if="!isExtended">За технической поддержкой можете обратиться на почту scientia.magicore@gmail.com</p>
        <div>
          <div class="account-button">
            <router-link to="/wells"><button>Перейти к скважинам</button></router-link>
          </div>
          <div class="account-button">
            <router-link to="/signout"><button>Выйти</button></router-link>
          </div>
          <div class="row"  style="grid-gap: 37px;">
            <div class="account-button">
              <button @click="changePassword()">Изменить пароль</button>
            </div>
            <div style="padding-top: 15px;" >
              <input type="password" placeholder="Новый пароль" v-model="newPassword">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/app/PageTitle.vue'
import { utf8_to_b64 } from '@/assets/js/base64.js'
import store from '@/store'

export default {
  name: "AccountView",
  components: {
    PageTitle
  },

  computed: {
    username () {
      return store.getters['account/getUserName']
    },

    isExtended() {
      return store.getters['account/isExtended']
    },
  },

  data: () => ({
    newPassword: ''
  }),

  methods: {
    async changePassword () {
      if (this.newPassword.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Пароль не может быть пустым', style: 'error'})
        return
      }
      const payload = {
        id: this.$store.getters['account/getID'],
        password: utf8_to_b64(this.newPassword),
      }
      const result = await this.$api.auth.changePassword(payload)
      if (result.ok) {
        this.$api.eventBus.$emit('toast', {text: 'Пароль успешно изменен', style: 'success'})
      }
    }
  }

}
</script>

<style>

</style>