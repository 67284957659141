<template>
  <div>
    <page-title
    :title="'Пользователи системы'"
    />
    <div class="card-wrap-full">
      <div class="card">
        <div class="table-table">
          <table>
            <thead>
              <th width="5%">id</th>
              <th>ФИО</th>
              <th>Email</th>
              <th>Организация</th>
              <th width="12%">Роль</th>
              <th width="10%">Скважины</th>
              <th width="10%">Статус</th>
            </thead>
            <tbody>
              <tr
                v-for="item in accounts" :key="item.id"
              >
                <td>{{ item.id }}</td>
                <td align="left">{{ item.name1 }} {{ item.name2 }}</td>
                <td align="left">{{ item.email }}</td>
                <td align="left">{{ item.company }}</td>
                <td align="left">
                  <span v-if="item.isSuper==1">&nbsp;&nbsp;&nbsp;</span>
                  <i v-if="(item.isSuper==0)" class="fa fa-arrow-up cursor" @click="accessUp(item)"></i>
                  <i v-if="(item.isExtended==1)||(item.isSuper==1)" class="fa fa-arrow-down cursor" @click="accessDown(item)"></i>
                  <span v-else>&nbsp;&nbsp;&nbsp;</span>
                  &nbsp;
                  {{ item.rolesTitle }}
                </td>
                <td><i class="fas fa-bars cursor" @click="openWells(item)"></i></td>
                <td>
                  <i v-if="item.status==1" class="fa fa-check cursor" @click="invertStatus(item)"></i>
                  <i v-if="item.status==0" class="fa fa-ban cursor" @click="invertStatus(item)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ConfirmDlg ref="confirmAdmin" />
  </div>
</template>

<script>
import PageTitle from '@/components/app/PageTitle.vue'
import ConfirmDlg from '@/components/dialogs/ConfirmDlg'
export default {
  name: 'AccountsView',
  components: {
    PageTitle, ConfirmDlg
  },

  data: () => ({
    accounts: []
  }),

  mounted () {
    this.loadAccounts()
  },

  methods : {
    
    async loadAccounts() {
      const result = await this.$api.auth.getAccounts('all')
      if (result.accounts) {
        this.accounts = result.accounts
      }
    },

    async invertStatus(item) {
      const result = await this.$api.auth.invertStatus(item.id)
      if (result.ok) {
        const index = this.accounts.findIndex(element => element.id === item.id)
        this.accounts[index].status = result.status
      }
    },

    async accessUp(item) {
      if (item.isExtended == 1) {
        const ok = await this.$refs.confirmAdmin.show({
            title: 'Подтвердите свое действие',
            message: 'Вы точно хотите сделать данного пользователя админом?',
            okButton: 'Да',
          })
        if (!ok.accept) {
          return
        }
      }

      const result = await this.$api.auth.accessUp(item.id)
      if (result.ok) {
        const index = this.accounts.findIndex(element => element.id === item.id)
        this.accounts[index].isSuper = (this.accounts[index].isExtended) ? 1 : 0
        this.accounts[index].isExtended = (this.accounts[index].isExtended) ? 0 : 1
        this.accounts[index].rolesTitle = result.roleTitle
      }
    },

    async accessDown(item) {
      const result = await this.$api.auth.accessDown(item.id)
      if (result.ok) {
        const index = this.accounts.findIndex(element => element.id === item.id)
        this.accounts[index].isExtended = (this.accounts[index].isSuper) ? 1 : 0
        this.accounts[index].isSuper = (this.accounts[index].isSuper) ? 0 : 1
        this.accounts[index].rolesTitle = result.roleTitle
      }
    },

    async openWells(item) {
      const result = await this.$api.auth.accessExternal(item.id)
      if (result.jwt) {
        window.open('/wells?jwt='+result.jwt)
      }

    }

  }
}
</script>

<style>

</style>