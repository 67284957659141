<template>
  <div>
    <page-title
    :title="'Автоматизированная<br>обработка фотографий керна'"
    />
    <div class="card-wrap-full">
      <div class="card">
        <div class="buttons-row-center">
          <router-link to="/fileupload"><button>Перейти к обработке</button></router-link>
        </div>
      </div>
    </div>

    <div class="text-wrap" v-html="pageText">
    </div>


  </div>
</template>

<script>
import PageTitle from '@/components/app/PageTitle.vue'

export default {
  name: 'HomeView',

  components: {
    PageTitle
  },

  data: () => ({
    pageText: ''
  }),

  async created () {
    this.pageText = await this.$api.html.getHTML('index.html')
  },

  mounted () {
    this.$store.dispatch('well/setWell', null)
  }

}
</script>
