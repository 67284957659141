import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ManualView from '../views/ManualView.vue'
import RegisterView from '../views/RegisterView.vue'
import SigninView from '../views/SigninView.vue'
import QuitView from '../views/QuitView.vue'
import WellsView from '../views/WellsView.vue'
import WellDetailsView from '../views/WellDetailsView.vue'
import WellPropsView from '../views/WellPropsView.vue'
import AccountView from '../views/AccountView.vue'
import AccountsView from '../views/AccountsView.vue'
import UploadFileView from '../views/UploadFileView.vue'
import WellResultsView from '../views/WellResultsView.vue'
import WellsAllView from '../views/WellsAllView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta : {layout : 'main'},
    component: HomeView
  },

  {
    path: '/about',
    name: 'about',
    meta : {layout : 'main'},
    component: AboutView
  },

  {
    path: '/manual',
    name: 'manual',
    meta : {layout : 'main'},
    component: ManualView
  },

  {
    path: '/signup',
    name: 'signup',
    meta : {layout : 'main'},
    component: RegisterView
  },

  {
    path: '/signin',
    name: 'signin',
    meta : {layout : 'main'},
    component: SigninView
  },

  {
    path: '/signout',
    name: 'QuitView',
    meta : {layout : 'main'},
    component: QuitView
  },

  {
    path: '/wells',
    name: 'wells',
    meta : {layout : 'main'},
    component: WellsView
  },

  {
    path: '/fileupload',
    name: 'fileupload',
    meta : {layout : 'main', auth: false},
    component: UploadFileView
  },

  {
    path: '/well',
    name: 'welldetails',
    meta : {layout : 'main', auth: false},
    component: WellDetailsView
  },

  {
    path: '/well/props',
    name: 'wellprops',
    meta : {layout : 'main', auth: true},
    component: WellPropsView
  },

  {
    path: '/well/results',
    name: 'wellresults',
    meta : {layout : 'main', auth: false},
    component: WellResultsView
  },
  
  {
    path: '/allwells',
    name: 'allwells',
    meta : {layout : 'main', auth: true},
    component: WellsAllView
  },

  {
    path: '/account',
    name: 'account',
    meta : {layout : 'main', auth: true},
    component: AccountView
  },

  {
    path: '/accounts',
    name: 'accounts',
    meta : {layout : 'main', auth: true},
    component: AccountsView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth) {
    if (!store.getters['account/isAuthenticated']) {
          next({ name: 'home' })
        }
      }
  next()
})


export default router
