export default {
  namespaced : true,
  state: {
    well: undefined,
  },
  getters : {
    getID (state) {
      return (state.well) ? state.well.id  : '0'
    },

    getWell (state) {
      return (state.well) ? state.well  : {id: 0, title : '2'}
    }

  },
  mutations : {
    SETWELL(state, well) {
      state.well = well
    },
  },
  actions : {
    setWell({ commit }, well) {
      commit('SETWELL', well)
    },

  }
}