<template>
  <div>
    <header-nav/>
    <main>
      <router-view />

    </main>
  </div>
</template>

<script>
import HeaderNav from '@/components/app/HeaderNav.vue'

export default {
  name : 'main-layout',
  components : {
    HeaderNav
  },

  data: () => ({

  }),

  async mounted() {

  }

}
</script>

