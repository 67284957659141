<template>
  <div id="wells">
    <div class="card-wrap-medium" v-if="isLogged">
      <div class="row well-tools ">
        <div class="row well-tools__title">Обработанные скважины</div>
        <div><router-link to="/fileupload"><button>Новая</button></router-link></div>
      </div>
  
      <div class="card">
        <div class="well-card"
          v-for="well in wells" :key="well.id"
        >
          <div class="well-card__title">{{ well.title }}</div>
          <div class="row">
            <div class="well-card__statistic">
              <div>{{ well.imgCount }} изобр.</div>
              <div>{{ well.date }}</div>
            </div>
            <div>
              <i v-if="well.results == 2" class="fas fa-spinner fa-pulse"></i>
              <button style="margin-left:10px;" @click="detailsWell(well)">Глубины</button>
              <button v-if="well.results == 4" style="margin-left:10px; background-color: rgb(22, 7, 74); cursor: default;" :class="{disabled : well.results != 1}">Прервано</button>
              <button v-if="well.results == 3" style="margin-left:10px; background-color: rgb(160, 10, 10); cursor: default;" :class="{disabled : well.results != 1}">Ошибка</button>
              <button v-if="well.results <= 2" style="margin-left:10px;" @click="resultWell(well)" :class="{disabled : well.results != 1}">Результаты</button>
              <button class="orange" style="margin-left:10px;" @click="deleteWell(well)">Удалить</button>
            </div>
          </div>
          <div>
            <hr/>
          </div>
        </div>

        <div v-if="(wells.length == 0)&&(JWTchecked)">Список скважин пуст</div>
      </div>
    </div>
    <div v-else>
      <page-title
        :title="'Для обработки изображений<br>необходимо авторизоваться'"
        />
        <div class="card-wrap-full">
          <div class="card">
            <div class="buttons-row-center">
              <router-link to="/signin"><button>Вход</button></router-link>
              <router-link to="/signup"><button>Регистрация</button></router-link>
            </div>
          </div>
        </div>
    </div>
    <ConfirmDlg ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDlg from '@/components/dialogs/ConfirmDlg'
import PageTitle from '@/components/app/PageTitle.vue'
import store from '@/store'
export default {
  name: "WellsView",
  components: {
    PageTitle, ConfirmDlg
  },

  data: () => ({
    wells: [],
    timerResult: null,
    JWTchecked: false
  }),

  computed: {
    isLogged () {
      return store.getters['account/isAuthenticated']
    },
  },

  async mounted () {
    this.$api.eventBus.$on('JWTchecked', this.loadWells)
    this.$store.dispatch('well/setWell', null)
    if (this.isLogged) {
      this.loadWells()
      if (this.timerResult == null) {
        this.timerResult = setInterval(this.checkResults, 2000);
      }
    }
  },


  methods : {
    async loadWells() {
      const response = await this.$api.wells.getWells(0)
      if (response.data) {
        this.wells = response.data
      }
      this.JWTchecked = true
    },

    detailsWell(well) {
      this.$store.dispatch('well/setWell', well)
      this.$router.push('/well')
    },

    resultWell(well) {
      if (well.results != 0) {
        this.$store.dispatch('well/setWell', well)
        this.$router.push('/well/results')
      }
    },

    async deleteWell(well) {
      const ok = await this.$refs.confirmDelete.show({
                title: 'Подтвердите свое действие',
                message: 'Вы точно хотите удалить скважину ' + well.title,
                okButton: 'Удалить',
            })
      if (ok.accept) {
        const response = await this.$api.wells.delete(well.id)
        if (response.ok) {
          this.loadWells()
        }
      }
    },

    async checkResults() {
      if (!document.getElementById('wells')) {
        clearInterval(this.timerResult)
        return
      }
      const response = await this.$api.wells.getResults(0)
      if (response.data) {
        response.data.forEach(element => {
          let index = this.wells.findIndex(el => el.id === element.id)
          if (index >= 0) {
            this.wells[index].results = element.results
          }
        })
      }
    }
  }
}
</script>

<style>

</style>