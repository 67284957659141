<template>
  <popup-modal ref="popup">
    <span class="title">{{ title }}</span>
    <p v-html="message"></p>
    <div class="text-right">
      <button class="small" @click.prevent="_confirm">{{ okButton }}</button> &nbsp;
      <button class="small"  @click="_cancel">{{ cancelButton }}</button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from '@/components/dialogs/PopupModal'

export default {
  name: 'ConfirmDlg',
  components : {
    PopupModal
  },
  data: () => ({
    title: undefined,
    message: undefined, // Main text content
    okButton: 'Войти',
    cancelButton: 'Отмена', // text for cancel button

    
    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
        this.title = opts.title;
        this.message = opts.message
        this.okButton = opts.okButton
        this.$refs.popup.open()
        // Return promise so the caller can get results
        return new Promise((resolve, reject) => {
            this.resolvePromise = resolve
            this.rejectPromise = reject
        })
    },

    async _confirm() {
      this.$refs.popup.close()
      this.resolvePromise({accept : true})
    },

    _cancel() {
        this.$refs.popup.close()
        this.resolvePromise({accept : false})
    },
  }
}
</script>

<style>

</style>