<template>
    <div class="card-wrap-medium" style="margin-top: 53px">
      <div class="card">
        <div class="card-title">Настройки вывода результатов</div>
        <div class="row properties">
          <div class="half padd20">
            <div class="properties-title">Рамки</div>
            <div class="properties-row">
              <div><input type="checkbox" class="input-checkbox"  v-model="props.rect.inter"/></div>
              <div class="properties-label">Интервалы</div>
            </div>
            <div class="properties-row">
              <div><input type="checkbox" class="input-checkbox"  v-model="props.rect.crack"/></div>
              <div class="properties-label">Трещины</div>
            </div>
            <div class="properties-row">
              <div><input type="checkbox" class="input-checkbox" :disabled="!(isExtended||isSuper)" v-model="props.rect.table"/></div>
              <div class="properties-label"  :class="{disabled : !(isExtended||isSuper)}">Таблички</div>
            </div>
            <div class="properties-row"></div>

            <div class="properties-title">Показатели</div>
            <div class="properties-row">
              <div><input type="radio" name="p1" class="input-checkbox" v-model="props.indicators" value="rqd"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">RQD</div>
            </div>
            <div class="properties-row">
              <div><input type="radio" name="p1" class="input-checkbox" :disabled="!(isExtended||isSuper)" v-model="props.indicators" value="ffm"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">FFm</div>
            </div>
            <div class="properties-row">
              <div><input type="radio" name="p1" class="input-checkbox" :disabled="!(isExtended||isSuper)" v-model="props.indicators" value="aplha"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">Угол alpha</div>
            </div>
            <div class="properties-row"></div>

          </div>

          <div class="padd20">
            <div class="properties-title">Глубины</div>
            <div class="properties-row">
              <div><input type="checkbox" class="input-checkbox" :disabled="!(isExtended||isSuper)"  v-model="props.depths.center"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">Цельный керн</div>
            </div>
            <div class="properties-row">
              <div><input type="checkbox" class="input-checkbox" :disabled="!(isExtended||isSuper)"  v-model="props.depths.rockBreak"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">Разломы</div>
            </div>
            <div class="properties-row">
              <div><input type="checkbox" class="input-checkbox" :disabled="!(isExtended||isSuper)"  v-model="props.depths.crack"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">Трещины</div>
            </div>
            <div class="properties-row"></div>
            <div class="properties-title">Трещины</div>
            <div class="properties-row">
              <div><input type="radio" name="p2" class="input-checkbox" :disabled="!(isExtended||isSuper)" v-model="props.crack" value="elipse"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">Эллипс</div>
            </div>
            <div class="properties-row">
              <div><input type="radio" name="p2" class="input-checkbox" :disabled="!(isExtended||isSuper)" v-model="props.crack" value="segment"/></div>
              <div class="properties-label" :class="{disabled : !(isExtended||isSuper)}">Сегментация</div>
            </div>
            <div class="properties-row">
              <div><input type="radio"  name="p2" class="input-checkbox"  v-model="props.crack" value="not"/></div>
              <div class="properties-label">Нет</div>
            </div>
            <div class="properties-row"></div>


          </div>
        </div>
        <div  class="buttons-row-center">
          <button @click="save()">Сохранить</button>
          <router-link to="/well"><button>Отменить</button></router-link>
        </div>
        <div class="card-text">
          Настройки вывода результатов доступны только для пользователей с полным доступом 
        </div>
      </div>
    </div>
</template>

<script>
import store from '@/store'
export default {
  name: 'WellPropsView',

  computed: {
    isSuper () {
      return store.getters['account/isSuper']
    },

    isExtended() {
      return store.getters['account/isExtended']
    },
  },

  data: () => ({
    id : 0,
    props: {
      rect: {
        inter : false,
        crack : false,
        table : false
      },
      depths: {
        center : false,
        rockBreak : false,
        crack : false,
      },
      indicators : 'ffm',
      crack : 'elipse'
    },
  }),

  async mounted () {
    this.id = store.getters['well/getID']
    const response = await this.$api.wells.getProps(this.id)
    if (response.data) {
      let props = response.data
      props.rect.inter = (props.rect.inter == 1) ? true : false
      props.rect.crack = (props.rect.crack == 1) ? true : false
      props.rect.table = (props.rect.table == 1) ? true : false
      props.depths.center = (props.depths.center == 1) ? true : false
      props.depths.rockBreak = (props.depths.rockBreak == 1) ? true : false
      props.depths.crack = (props.depths.crack == 1) ? true : false
      this.props = props
    }

  },

  methods : {
    async save () {
      let props = this.props
      props.rect.inter = (props.rect.inter) ? 1 : 0
      props.rect.crack = (props.rect.crack) ? 1 : 0
      props.rect.table = (props.rect.table) ? 1 : 0
      props.depths.center = (props.depths.center) ? 1 : 0
      props.depths.rockBreak = (props.depths.rockBreak) ? 1 : 0
      props.depths.crack = (props.depths.crack) ? 1 : 0

      const response = await this.$api.wells.updateProps(this.id, props)
      if (response.ok) {
        this.$router.push('/well')
      }
    }
  }

}
</script>

<style>

</style>