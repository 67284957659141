<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
    <toast-message />
  </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import ToastMessage from '@/components/app/ToastMessage.vue'

export default {
  name: 'App',
  data: () => ({
  }),

  computed : {
    layout() {
      return (this.$route.meta.layout || 'main') +'-layout'
    }
  },
  components : {
    MainLayout, ToastMessage
  },

  async created () {
    const jwt = (this.$route.query.jwt) ? this.$route.query.jwt : this.$api.cookie.getCookie('jwt')

    if (jwt != '') {
      // Авторизованный пользователь - надо его проверить
      this.$api.auth.setJWT(jwt)
      const response = await this.$api.auth.validateJWT(jwt)
      if ((response.id)) {
        response.jwt = jwt
        this.$store.dispatch('account/setAccount', response)
        this.$api.eventBus.$emit('JWTchecked')
        return
      }
      this.$api.auth.setJWT('')
      this.$api.cookie.setCookie('jwt', '', 1)
      if (this.$route.path != '/') {
          this.$router.push('/')
        }
    }
  },

  async mounted() {
    this.$api.eventBus.$on('network-error', this.network_error)
  },

  methods : {
    network_error(error) {
      if ((error.response)&&(error.response.data)&&(error.response.data.error)) {
        this.$api.eventBus.$emit('toast', {text: error.response.data.error, style: 'error'})
        return
      }
      this.$api.eventBus.$emit('toast', {text: 'Ошибка сети!', style: 'error'})
    }
  }


}
</script>