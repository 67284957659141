<template>
  <div>
    <page-title
    :title="'Регистрация'"
    />
    <div class="card-wrap-medium">
      <div class="card">
        <div class="row" style="grid-gap: 37px;">
          <div>
            <input class="input-left" type="text" placeholder="Имя" v-model="account.name1">
          </div>
          <div>
            <input class="input-right" type="text" placeholder="Фамилия" v-model="account.name2">
          </div>
        </div>
        <div>
          <input type="text" placeholder="Организация" v-model="account.company">
        </div>
        <div>
          <input type="text" placeholder="E-mail" v-model="account.email">
        </div>
        <div class="row" style="grid-gap: 37px;">
          <div>
            <input class="input-left" type="password" placeholder="Пароль" v-model="account.password">
          </div>
          <div>
            <input class="input-right" type="password" placeholder="Подтвердите пароль" v-model="account.password2">
          </div>
        </div>

        <div class="buttons-row-center">
          <button @click="checkData">Регистрация</button>
        </div>

        <div class="register-text">
          Уже зарегистрированы? <router-link to="/signin" class="link">Войдите</router-link> в свой аккаунт
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/app/PageTitle.vue'
import { utf8_to_b64 } from '@/assets/js/base64.js'

export default {
  name: 'RegisterView',
  components: {
    PageTitle
  },

  data: () => ({
    account: {
      name1: '',
      name2: '',
      company: '',
      email: '',
      password: '',
      password2: ''
    }
  }),

  methods: {
    async checkData () {
      let error = false
      if (this.account.name1.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Имя не может быть пустым', style: 'error'})
        error = true
      }

      if (this.account.name2.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Фамилия не может быть пустой', style: 'error'})
        error = true
      }

      if (this.account.company.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Компания не может быть пустой', style: 'error'})
        error = true
      }

      if (this.account.email.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Емайл не может быть пустой', style: 'error'})
        error = true
      }

      if (this.account.password.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Пароль не может быть пустым', style: 'error'})
        error = true
      }

      if ((this.account.password.length > 0)&&(this.account.password != this.account.password2)) {
        this.$api.eventBus.$emit('toast', {text: 'Пароли не совпадают', style: 'error'})
        error = true
      }

      if (!error) {
        const payload = {
          id: 0,
          name1: utf8_to_b64(this.account.name1),
          name2: utf8_to_b64(this.account.name2),
          company: utf8_to_b64(this.account.company),
          email: utf8_to_b64(this.account.email),
          password: utf8_to_b64(this.account.password),
          status: 1
        }
        const result = await this.$api.auth.signUp(payload)
        if (result.jwt) {
          this.$api.auth.setJWT(result.jwt)
          const response = await this.$api.auth.validateJWT(result.jwt)
          if ((response.id)) {
            response.jwt = result.jwt
            this.$store.dispatch('account/setAccount', response)
            this.$api.eventBus.$emit('JWTchecked')
            // сохраняем токен в куках
            this.$api.cookie.setCookie('jwt', result.jwt, 7)
            this.$router.push('/fileupload')
            return
          }

        }
      }
    }
  }

}
</script>

<style>

</style>