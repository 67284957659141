export default function (instance) {
  return {
    setJWT(jwt) {
      instance.setJWT(jwt)
    },

    async signUp(payload) {
      return await instance.post('account/signup', payload)
    },

    async signIn(payload) {
      return await instance.post('account/signin', payload)
    },

    async validateJWT(jwt) {
      return await instance.getWithoutCheck('account/validateJWT/'+jwt)
    },

    async invertStatus(accountID) {
      return await instance.post('account/status/invert/'+accountID)
    },

    async accessUp(accountID) {
      return await instance.post('account/access/up/'+accountID)
    },

    async accessDown(accountID) {
      return await instance.post('account/access/down/'+accountID)
    },

    async accessExternal(accountID) {
      return await instance.get('account/access/external/'+accountID)
    },

    async getAccounts(filter) {
      return await instance.get('account/user/list/'+filter)
    },

    async changePassword(data) {
      return await instance.post('account/changepassword', data)
    },

  }
}