export default function (instance) {
  return {
    async updateProps(wellID, props) {
      return await instance.post('well/props/update/'+wellID, props)
    },

    async update(data) {
      return await instance.post('well/update', data)
    },

    async delete(wellID) {
      return await instance.post('well/delete/'+wellID)
    },

    async resetResult(wellID) {
      return await instance.post('well/result/reset/'+wellID)
    },

    async fulldelete(wellID) {
      return await instance.post('well/delete/full/'+wellID)
    },

    async exec(wellID) {
      return await instance.post('well/exec/'+wellID)
    },

    async getProps(wellID) {
      return await instance.get('well/props/'+wellID)
    },

    async getWells(accountID) {
      return await instance.get('well/list/'+accountID)
    },

    async getWell(wellID) {
      return await instance.get('well/details/'+wellID)
    },

    async getAllWells(page) {
      return await instance.get('well/list/all/'+page)
    },

    async getResults(accountID) {
      return await instance.get('well/results/'+accountID)
    },

    async getFiles(wellID) {
      return await instance.get('well/files/'+wellID)
    },

    async getResultFiles(wellID) {
      return await instance.get('well/resultfiles/'+wellID)
    },

    async deleteFile(fileID) {
      return await instance.post('well/files/delete/'+fileID)
    },

    async downFile(fileID) {
      return await instance.post('well/files/down/'+fileID)
    },

    async upFile(fileID) {
      return await instance.post('well/files/up/'+fileID)
    },

    async invertStatus(wellID) {
      return await instance.post('well/status/invert/'+wellID)
    },

  }
}