<template>
  <div>
    <div class="row well-tools">
      <div class="row well-tools__title">{{ well.title }}</div>
      <div><button @click="downloadIMGs()">Скачать изображения</button></div>
      <div><button @click="downloadExcel()">Скачать Excel</button></div>
    </div>
    <div class="card-wrap-full">
      <div class="card">
        <div class="well-img"
          v-for="(file) in files" :key="file.id"
        >
          <div>
            <img class="cursor" :src=file.src :alt="file.filename" @click="openIMG(file.src)">
          </div>
          <div class="well-img__tools">
            <div class="title">{{ file.filename }}</div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row well-tools">
      <div  style="margin-left:auto;"><button @click="downloadIMGs()">Скачать изображения</button></div>
      <div><button @click="downloadExcel()">Скачать Excel</button></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WellResultsView",
  data: () => ({
    well: {
      id: 0,
      title: '',
    },
    files : [],
  }),

  async mounted () {
    if (this.$route.query.wellID) {
      const response = await this.$api.wells.getWell(this.$route.query.wellID)
      if (response.well) {
        this.well = response.well;
        this.$store.dispatch('well/setWell', this.well)
        this.loadFiles()
      }

    }
    else {
      this.well = this.$store.getters['well/getWell']
      this.loadFiles()
    }
  },

  methods: {
    async loadFiles() {
      const response = await this.$api.wells.getResultFiles(this.well.id)
      if (response.data) {
        response.data.forEach((element, index) => {
          response.data[index]['src'] = this.$api.files.getFileURLByID(element.fileUUID)
        })
        this.files = response.data
      }
    },

    downloadIMGs () {
      window.open(this.$api.files.getFileURLByID('result/'+this.well.id+'/zip/'+this.well.id+' '+this.well.title+'.zip?v='+Math.random()))
    },

    openIMG(src) {
      window.open(src)
    },

    async downloadExcel() {
      window.open(this.$api.files.getFileURLByID('result/'+this.well.id+'/excel/'+this.well.id+' '+this.well.title+'.xlsx?v='+Math.random()))
    },


  }
}
</script>

<style>

</style>