import axios from 'axios'

export default function () {
  return {
    async getHTML(pageName) {
      const response = await axios.get("/html/"+pageName)
      return response.data
    },
    
  }
}