export default function (instance) {
  return {

    async sendFilesToWell(wellID, formData, callback) {
      return await instance.postFiles('files/well/upload/'+wellID, formData, callback)
    },

    getFileURLByID(fileID) {
      return instance.baseURL+'files/download/'+fileID
    }

  }
}