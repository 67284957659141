<template>
  <div>
    <div class="row well-tools">
      <div class="row well-tools__title">Фотографии скважин</div>
      <div><button @click="addIMG()">Добавить изображения</button></div>
      <div><button @click="save()">Сохранить и обработать</button></div>
    </div>
    <div class="card-wrap-full">
      <div class="card">
        <div class="row">
          <div style="width:90%">
            <input type="text" placeholder="Название скважины" v-model="well.title" v-on:keypress="onTitleChange($event)">
          </div>
          <div style="margin-left:auto;">
            <router-link to="/well/props"><img class="cursor" src="/img/prop.png" alt="Настройки"></router-link>
          </div>
        </div>

        <div class="well-img"
          v-for="(file, index) in files" :key="file.id"
        >
          <div>
            <img class="cursor" :src=file.src :alt="file.filename" @click="openIMG(file.src)">
          </div>
          <div class="well-img__tools">
            <div class="title">{{ file.filename }}</div>
            <div class="box"><input type="number" placeholder="От" v-model="file.from" v-on:keypress="onNumberChange($event)"></div>
            <div class="box"><input type="number" placeholder="До" v-model="file.to" v-on:keypress="onNumberChange($event)"></div>
            <div class="box"><button @click="deleteIMG(file)"><i class="fas fa-times"></i></button></div>
            <div class="box"><button :class="{disabled : index == 0}" @click="upIMG(file, index == 0)"><i class="fas fa-chevron-up"></i></button></div>
            <div class="box"><button :class="{disabled : index == files.length-1}" @click="downIMG(file, index == files.length-1)"><i class="fas fa-chevron-down"></i></button></div>
            <div class="box number"><input type="text" readonly v-model="file.ord"></div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row well-tools">
      <div style="margin-left:auto;" @click="addIMG()"><button>Добавить изображения</button></div>
      <div><button @click="save()">Сохранить и обработать</button></div>
    </div>

    <ConfirmDlg ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDlg from '@/components/dialogs/ConfirmDlg'
import store from '@/store'
import { utf8_to_b64 } from '@/assets/js/base64.js'

export default {
  name: "WellDetailsView",
  components : {
    ConfirmDlg
  },

  computed: {
    isSuper () {
      return store.getters['account/isSuper']
    },

    isExtended() {
      return store.getters['account/isExtended']
    },

  },

  data: () => ({
    well: {
      id: 0,
      title: '',
    },
    files : [],
  }),

  async mounted () {
    if (this.$route.query.wellID) {
      const response = await this.$api.wells.getWell(this.$route.query.wellID)
      if (response.well) {
        this.well = response.well;
        this.$store.dispatch('well/setWell', this.well)
        this.loadFiles()
      }

    }
    else {
      this.well = this.$store.getters['well/getWell']
      this.loadFiles()
    }

  },

  methods: {
    onTitleChange (event) {
      if (!event.key.match(/[a-z0-9 а-я_-]/i)) {
        event.preventDefault();
        return false;
      }
    },

    onNumberChange(event) {
      if (!event.key.match(/[0-9.]/i)) {
        event.preventDefault();
        return false;
      }
    },

    async loadFiles() {
      const response = await this.$api.wells.getFiles(this.well.id)
      if (response.data) {
        response.data.forEach((element, index) => {
          response.data[index]['src'] = this.$api.files.getFileURLByID(element.fileUUID)
        })
        this.files = response.data
      }
    },

    addIMG () {
      if ((!(this.isExtended||this.isSuper))&&(this.files.length > 0)) {
        this.$api.eventBus.$emit('toast', {text: 'Вы не можете загрузить более одной фотографии', style: 'error'})
        return 
      }
      this.$router.push('/fileupload')
    },

    openIMG(src) {
      window.open(src)
    },

    async save() {
      if (this.well.title === '') {
        this.$api.eventBus.$emit('toast', {text: 'Имя скважины не может быть пустым', style: 'error'})
        return 
      }

      if (this.files.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Необходимо загрузить хотя бы одну фотографию', style: 'error'})
        return 
      }

      if (this.files[0].from == '') {
        this.$api.eventBus.$emit('toast', {text: 'Необходимо заполнить поле "От" первой фотографии', style: 'error'})
        return 

      }

      if (this.files[this.files.length-1].to == '') {
        this.$api.eventBus.$emit('toast', {text: 'Необходимо заполнить поле "До" последней фотографии', style: 'error'})
        return 

      }

      let error = '';
      this.files.forEach(element => {
        if ((element.from!='')&&(element.to!='')) {
          if (Number(element.from) > Number(element.to))
          {
            error = element.filename + ' - "от" не может быть больше "до"'
          }
        }
      })

      if (error != '') {
        this.$api.eventBus.$emit('toast', {text: error, style: 'error'})
        return 
      }


      let wellData = Object.assign({}, this.well);
      wellData.files = this.files

      wellData.title = utf8_to_b64(wellData.title)
      const response = await this.$api.wells.update(wellData)
      if (response.ok) {
        this.loadFiles()
        const response = await this.$api.wells.exec(this.well.id)
        if (response.ok) {
          this.$router.push('/wells')
        }
      }

    },

    async deleteIMG(file) {
      const ok = await this.$refs.confirmDelete.show({
                title: 'Подтвердите свое действие',
                message: 'Вы точно хотите удалить картинку ' + file.filename,
                okButton: 'Удалить',
            })
      if (ok.accept) {
        const response = await this.$api.wells.deleteFile(file.id)
        if (response.ok) {
          this.loadFiles()
        }
      }
    },

    async downIMG(file, last) {
      if (!last) {
        const response = await this.$api.wells.downFile(file.id)
        if (response.ok) {
          this.loadFiles()
        }
      }
    },

    async upIMG(file, first) {
      if (!first) {
        const response = await this.$api.wells.upFile(file.id)
        if (response.ok) {
          this.loadFiles()
        }
      }
    },


  }
}
</script>

<style>

</style>