<template>
  <header>
    <div class="logo">
      <router-link to="/">MAGICORE</router-link>
    </div>
    <div>
      <ul>
        <li><router-link to="/manual">Руководство</router-link></li>
        <li><router-link to="/about">O нас</router-link></li>
      </ul>
    </div>
    <div class="sign-wrap">
      <div v-if="isLogged&&isSuper"><router-link to="/allwells">Все скважины</router-link></div>
      <div v-if="isLogged&&isSuper"><router-link to="/accounts">Пользователи</router-link></div>
      <div v-if="isLogged"><router-link to="/wells">Скважины</router-link></div>
      <div v-if="isLogged"><router-link to="/account">{{ username }}</router-link></div>
      <div v-if="isLogged"><router-link to="/signout" @click="signout()">Выход</router-link></div>
      <div v-if="!isLogged"><router-link to="/signin">Вход</router-link></div>
      <div v-if="!isLogged"><router-link to="/signup">Регистрация</router-link></div>

    </div>
  </header>
</template>

<script>
import store from '@/store'
export default {
  name: "HeaderNav",

  computed: {
    isLogged () {
      return store.getters['account/isAuthenticated']
    },

    isSuper () {
      return store.getters['account/isSuper']
    },

    username () {
      return store.getters['account/getUserName']
    }
  },

  methods: {
    signout() {
      alert('aaaaa')
    }
  }
}
</script>

<style>

</style>