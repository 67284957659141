<template>
  <div class="page-title-wrap">
    <div v-html="title">
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitle",

  props: [ 'title' ]
}
</script>

<style>

</style>