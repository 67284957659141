<template>
  <div>
    <page-title
    :title="'Вход'"
    />
    <div class="card-wrap-small">
      <div class="card">
        <div>
          <input type="text" placeholder="E-mail" v-model="account.email">
        </div>
        <div>
          <input type="password" placeholder="Пароль" v-model="account.password">
        </div>

        <div class="buttons-row-center">
          <button @click="checkData">Войти</button>
        </div>

        <div class="register-text">
          Еще не зарегистрированы?<br>
          <router-link to="/signup" class="link">Зарегистрируйтесь</router-link> прямо сейчас
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PageTitle from '@/components/app/PageTitle.vue'
import { utf8_to_b64 } from '@/assets/js/base64.js'

export default {
  name: 'SigninView',
  components: {
    PageTitle
  },

  data: () => ({
    account: {
      email: '',
      password: '',
    }
  }),

  methods: {
    async checkData () {
      let error = false

      if (this.account.password.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Пароль не может быть пустым', style: 'error'})
        error = true
      }

      if (this.account.email.length == 0) {
        this.$api.eventBus.$emit('toast', {text: 'Емайл не может быть пустой', style: 'error'})
        error = true
      }

      if (!error) {
        const payload = {
          id: 0,
          email: utf8_to_b64(this.account.email),
          password: utf8_to_b64(this.account.password),
          status: 1
        }
        const result = await this.$api.auth.signIn(payload)
        if (result.jwt) {
          this.$store.dispatch('account/setAccount', result)
          this.$api.auth.setJWT(result.jwt)
          // сохраняем токен в куках
          this.$api.cookie.setCookie('jwt', result.jwt, 7)

          if (result.isSuper) {
            this.$router.push('/accounts')
            return
          } 
          this.$router.push('/wells')
        }
      }
    }
  }

}
</script>

<style>

</style>