<template>
  <div>
    <page-title
    :title="'Все скважины'"
    />
    <div class="card-wrap-full">
      <div class="card">
        <div class="table-table">
          <table>
            <thead>
              <th width="5%">id</th>
              <th>Дата создания 
                <span style="float: right;" class="cursor">
                  <i v-if="order_date!='down'" class="fa fa-long-arrow-down" @click="order_date_down()"></i>
                  <i v-if="order_date!='up'" class="fa fa-long-arrow-up" @click="order_date_up()"></i>
                </span>
              </th>
              <th>ФИО
                <span style="float: right;" class="cursor">
                  <i v-if="order_name!='down'" class="fa fa-long-arrow-down" @click="order_name_down()"></i>
                  <i v-if="order_name!='up'" class="fa fa-long-arrow-up" @click="order_name_up()"></i>
                </span>
              </th>
              <th>Email</th>
              <th>Организация</th>
              <th>Наименование скважины</th>
              <th width="10%"></th>
              <th width="10%">Статус</th>
            </thead>
            <tbody>
              <tr
                v-for="item in wells" :key="item.id"
              >
                <td>{{ item.id }}</td>
                <td align="left">{{ item.date }}</td>
                <td align="left">{{ item.user }}</td>
                <td align="left">{{ item.email }}</td>
                <td align="left">{{ item.company }}</td>
                <td align="left">{{ item.title }}</td>
                <td>
                  <div v-if="item.status==1" >
                    <i class="fas fa-bars cursor" @click="openWells(item)"></i>&nbsp;&nbsp;
                    <span v-if="item.results == 0">&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i v-if="item.results == 1" class="fas fa-list cursor" @click="openWellResult(item)"></i>
                    <i v-if="item.results == 2" class="fas fa-spinner fa-pulse cursor" @click="resetResult(item)"></i>
                    <i v-if="item.results == 3" class="fas fa-exclamation-circle"></i>
                    <i v-if="item.results == 4" class="fa fa-ban"></i>
                  </div>
                </td>
                <td>
                  <i v-if="item.status==1" class="fa fa-check cursor" @click="invertStatus(item)"></i>&nbsp;
                  <i v-if="item.status==0" class="fa fa-ban cursor" @click="invertStatus(item)"></i>
                  &nbsp;&nbsp;<i v-if="item.status==0" class="fa fa-trash cursor" @click="fullDeleteWell(item)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pages">
          <div>Страницы</div>
          <div v-for="item in pages" :key="item" :class="{bold : item == page}" @click="reloadPage(item)">
            {{ item+1 }}
          </div>
        </div>
      </div>
    </div>
    <ConfirmDlg ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDlg from '@/components/dialogs/ConfirmDlg'
import PageTitle from '@/components/app/PageTitle.vue'
import { sortUPbyField, sortDOWNbyField } from '@/assets/js/lib'

export default {
  name: 'WellsAllView',
  components: {
    PageTitle, ConfirmDlg
  },

  data: () => ({
    wells: [],
    order_date: 'down',
    order_name: 'none',
    page: 0,
    pages: [0, 1, 2, 3, 4],
  }),

  mounted () {
    this.loadAllWells()
  },

  methods : {
    
    async loadAllWells() {
      const result = await this.$api.wells.getAllWells(this.page)
      if (result.wells) {
        this.wells = result.wells
        this.pages = []
        for (let i = 0; i < result.pages; i++) {
          this.pages.push(i)
        }
      }
    },


    async openWells(item) {
      const result = await this.$api.auth.accessExternal(item.accountID)
      if (result.jwt) {
        window.open('/well?wellID='+item.id+'&jwt='+result.jwt)
      }

    },

    async openWellResult(item) {
      const result = await this.$api.auth.accessExternal(item.accountID)
      if (result.jwt) {
        window.open('/well/results?wellID='+item.id+'&jwt='+result.jwt)
      }

    },

    async invertStatus(item) {
      const result = await this.$api.wells.invertStatus(item.id)
      if (result.ok) {
        const index = this.wells.findIndex(element => element.id === item.id)
        this.wells[index].status = result.status
      }
    },

    order_date_up() {
      this.wells.sort(sortUPbyField('date'));
      this.order_date = 'up'
      this.order_name = 'none'
    },

    order_date_down() {
      this.wells.sort(sortDOWNbyField('date'));
      this.order_date = 'down'
      this.order_name = 'none'
    },

    order_name_up() {
      this.wells.sort(sortUPbyField('user'));
      this.order_date = 'none'
      this.order_name = 'up'
    },

    order_name_down() {
      this.wells.sort(sortDOWNbyField('user'));
      this.order_date = 'none'
      this.order_name = 'down'
    },

    async fullDeleteWell (well) {
      const ok = await this.$refs.confirmDelete.show({
                title: 'Подтвердите свое действие',
                message: `Скважина "${well.title}"<br>Вы точно хотите удалить БЕЗ ВОЗМОЖНОСТИ восстановления?`,
                okButton: 'Удалить',
            })
      if (ok.accept) {
        const response = await this.$api.wells.fulldelete(well.id)
        if (response.ok) {
          this.loadAllWells()
        }
      }
    },

    async resetResult(well) {
      const ok = await this.$refs.confirmDelete.show({
                title: 'Подтвердите свое действие',
                message: `Скважина "${well.title}"<br>Вы точно хотите сбросить статус "Обработка"?`,
                okButton: 'Сбросить',
            })
      if (ok.accept) {
        const response = await this.$api.wells.resetResult(well.id)
        if (response.ok) {
          this.loadAllWells()
        }
      }

    },

    reloadPage(newPage) {
      this.page = newPage
      this.loadAllWells()
    }

  }
}
</script>

<style>

</style>