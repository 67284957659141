<template>
  <div>
    <page-title v-if="isLogged"
    :title="'Загрузите фотографии<br>ящиков с керном'"
    />

    <div class="card-wrap-full"  v-if="isLogged">
      <div class="card">
        <div id="file-drag-drop" class="file-upload-box">
          <form ref="fileform">
            <div class="file-drag-drop__wrap" v-on:click="addFiles()"><span class="drop-files cursor">Перетащите сюда файлы или нажмите, чтобы загрузить</span></div>
            <div class="files-preview-wrap">
              <div v-for="(file, key) in files" :key="key"  class="file-preview">
                <div class="file-preview__remove">
                  <i class="fas fa-times" v-on:click="removeFile( key )"></i>
                </div>
                <div>
                  <img class="preview" v-bind:ref="'image'+parseInt( key )" />
                </div>
                <div class="file-preview__filename">
                  {{ file.name }}
                </div>
              </div>
            </div>
            <input type="file" id="files" ref="files" accept="image/*" class="none" multiple v-on:change="handleFilesUpload()"/>
          </form>
        </div>
        <div class="file-upload-tools">
          <div class="file-upload-tools__progress-bar">
            <div v-if="showProgress">
              <progress max="100" :value.prop="uploadPercentage"></progress>
            </div>

          </div>
      
          <div>
            <button v-on:click="submitFiles()" v-show="files.length > 0">Загрузить фотографии</button>
          </div>
        </div>

        <div class="card-text">
          <div>
            <span v-if="isExtended||isSuper">Вы можете загружать сразу несколько изображений</span>
            <span v-else v-html="pageText">
            </span>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <page-title
        :title="'Для обработки изображений<br>необходимо авторизоваться'"
        />
        <div class="card-wrap-full">
          <div class="card">
            <div class="buttons-row-center">
              <router-link to="/signin"><button>Вход</button></router-link>
              <router-link to="/signup"><button>Регистрация</button></router-link>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/app/PageTitle.vue'
import store from '@/store'



export default {
  name: 'UploadFileView',

  components: {
    PageTitle,
  },

  computed: {
    isLogged () {
      return store.getters['account/isAuthenticated']
    },

    isSuper () {
      return store.getters['account/isSuper']
    },

    isExtended() {
      return store.getters['account/isExtended']
    },
  },
  data: () => ({
    dragAndDropCapable: false,
    files: [],
    uploadPercentage: 0,
    showProgress: false,
    pageText: ''
  }),

  async created () {
    this.pageText = await this.$api.html.getHTML('fileupload.html')
  },

  mounted(){

    if (this.isLogged) {
      this.dragAndDropCapable = this.determineDragAndDropCapable();

      if( this.dragAndDropCapable ){
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function( evt ) {
          this.$refs.fileform.addEventListener(evt, function(e){
            e.preventDefault();
            e.stopPropagation();
          }.bind(this), false);
        }.bind(this));

        this.$refs.fileform.addEventListener('drop', function(e){
          if (!(this.isExtended||this.isSuper)) {
            if (this.files.length > 0) {
              this.$api.eventBus.$emit('toast', {text: 'Вы не можете загрузить более одной фотографии', style: 'error'})
              return
            }
            if (e.dataTransfer.files.length > 0) {
              this.files.push( e.dataTransfer.files[0]);
              this.getImagePreviews();
            }
            return
          }
          for( let i = 0; i < e.dataTransfer.files.length; i++ ){
            this.files.push( e.dataTransfer.files[i] );
          }

          this.getImagePreviews();
        }.bind(this));
      }
    }
  },


  methods:{
    determineDragAndDropCapable(){
      var div = document.createElement('div');
      return ( ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div ) )
              && 'FormData' in window
              && 'FileReader' in window;
    },



    removeFile( key ){
      this.files.splice( key, 1 );
    },



    async submitFiles(){
      this.showProgress = true;
      let formData = new FormData();

      for( var i = 0; i < this.files.length; i++ ){
        let file = this.files[i];

        formData.append('files[' + i + ']', file);
      }

      const response = await this.$api.files.sendFilesToWell(store.getters['well/getID'], formData, function( progressEvent ) {
              this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded * 100 ) / progressEvent.total ) );
            }.bind(this))
      this.showProgress = false
      if (response.ok) {
        console.log('OK', response);
        this.$store.dispatch('well/setWell', response.data)
        this.$router.push('/well')
      }
    },



    handleFilesUpload(){
      let uploadedFiles = this.$refs.files.files;
      if (this.isExtended||this.isSuper) {
        for( var i = 0; i < uploadedFiles.length; i++ ){
          this.files.push( uploadedFiles[i] );
        }
      }
      else {
        if (uploadedFiles.length > 0) {
          this.files.push( uploadedFiles[0] );
        }
      }

      this.getImagePreviews();
    },



    getImagePreviews(){
      for( let i = 0; i < this.files.length; i++ ){
        /*
          Ensure the file is an image file
        */
        if ( /\.(jpe?g|png|gif)$/i.test( this.files[i].name ) ) {
          /*
            Create a new FileReader object
          */
          let reader = new FileReader();

          /*
            Add an event listener for when the file has been loaded
            to update the src on the file preview.
          */
          reader.addEventListener("load", function(){
            this.$refs['image'+parseInt( i )][0].src = reader.result;
          }.bind(this), false);
          /*
            Read the data for the file in through the reader. When it has
            been loaded, we listen to the event propagated and set the image
            src to what was loaded from the reader.
          */
          reader.readAsDataURL( this.files[i] );
        }
      }
    },



    addFiles(){
      this.$refs.files.click();
    },


  }

}
</script>

<style scoped>

.file-drag-drop__wrap {
  background-color:#f1decf;
  margin: 20px 60px;
}

</style>