export default {
  namespaced : true,
  state: {
    account: undefined,
  },
  getters : {
    isAuthenticated (state) {
      return (state.account) ? true : false
    },

    isExtended (state) {
      return ((state.account)&&(state.account.isExtended)) ? true : false
    },

    isSuper (state) {
      return ((state.account)&&(state.account.isSuper)) ? true : false
    },

    getUserName (state) {
      return (state.account) ? `${state.account.name1} ${state.account.name2}`  : ''
    },

    getID (state) {
      return (state.account) ? state.account.id  : '0'
    }
    
  },
  mutations : {
    SETACCUNT(state, account) {
      state.account = account

    },

    SIGNOUT(state) {
      state.account = undefined
      
    },
  },
  actions : {
    setAccount({ commit }, account) {
      commit('SETACCUNT', account)
    },

    signout({ commit }) {
      commit('SIGNOUT')
    },

  }
}