<template>
  <transition name="toast">
    <div class="toast"
      :class="{ error : style == 'error', success : style == 'success'}"
      v-if="isVisible"
    >
    {{ text }}
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ToastMessage',
  data: () => ({
    isVisible: false,
    text: '12',
    style: 'normal'
  }),

  mounted () {
    this.$api.eventBus.$on('toast', this.show)
  },

  methods: {
    show(toast) {
      this.style = toast.style
      this.text = toast.text
      this.isVisible = true
      setTimeout(
        () => { this.isVisible = false },
        2000
      )

    }
  }
}
</script>

<style scoped>
.toast-enter-active,
.toast-leave-active {
    transition: opacity 1.0s;
}
.toast-enter,
.toast-leave-to {
    opacity: 0;
}

</style>