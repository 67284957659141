import axios from 'axios'

const baseURL = (process.env.NODE_ENV == 'development') ? 'http://127.0.0.1:5000/api/v1/' : 'http://'+window.location.host+'/api/v1/';

const instance = axios.create({
  baseURL : baseURL,
  withCredentials : false,
  headers : {
    'Access-Control-Allow-Origin' : '*',
    accept : 'application/json',
  }
})

const network = {
  baseURL : baseURL,
  instance: instance,
  eventBus : null,
  jwt : '',

  setEventBus(newEventBus) {
    this.eventBus = newEventBus;
  },

  setJWT(newJWT) {
    this.jwt = newJWT
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + newJWT;
  },

  async post(url, data) {
    let responseR = {}
    await instance.post(url, data)
    .then(response => responseR = response.data)
    .catch(error => {
      this.eventBus.$emit('network-error', error)
    })
    return responseR
  },

  async postFiles(url, formData, callback) {
    let responseR = {}
    await axios.post( url,
      formData,
      {
        baseURL : baseURL,
        withCredentials : false, 
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin' : '*',
            accept : 'application/json',
            'Authorization' : 'Bearer ' + this.jwt
        },
        onUploadProgress: callback
      }
    ).then(response => { 
      responseR = response.data
    })
    .catch(error => {
      this.eventBus.$emit('network-error', error)
    });
    return responseR
  },

  async postWithoutCheck(url, data) {
    let responseR
    await instance.post(url, data)
    .then(response => responseR = response.data)
    .catch(error => responseR = error)
    return responseR
  },

  async getWithoutCheck(url) {
    let responseR
    await instance.get(url)
    .then(response => responseR = response.data)
    .catch(error => responseR = error)
    return responseR
  },

  async get(url) {
    let responseR = {}
    await instance.get(url)
    .then(response => {responseR = response.data 
    })
    .catch(error => {
      this.eventBus.$emit('network-error', error)
    })
    return responseR
  },
}


export default network